import { useMemo } from "react"

/**
 * バックエンドAPIのURIを取得する
 * @export useRenkApiUri
 * @param {string} hostName
 * @return {string} RenkAPI Origin
 */
export function useRenkApiUri(hostName) {
  return useMemo(() => {
    if (hostName === "stg.renk.co.jp" || hostName === "localhost") {
      return "https://api.stg.renk.co.jp/stg_"
    }

    return "https://api.renk.co.jp/"
  }, [hostName])
}
